.OrderSummary {
  padding: 5px;
  text-align: center;
}

.OrderSummaryContainer {
  background-color: #e6eef0;
  border-radius: 5px;
  padding: 20px 15px;
  width: 100%;
  margin: 0 auto;
}

.OrderSummary thead th {
  font-weight: bold;
  padding: 10px 0;
}

.OrderSummary tbody tr:nth-of-type(even) {
  background-color: #cedde1;
}

.OrderSummary tr td {
  padding: 10px 0;
}

.Title {
  text-align: center;
  color: #004e63;
  color: var(--main-colour);
  font-size: 25px;
  padding: 15px 0;
}

.Disclaimer {
  font-size: 14px;
  text-align: right;
  padding-top: 10px;
}

.Total {
  font-weight: bold;
  padding: 10px 0;
}

.Total td:nth-of-type(3),
.Total td:nth-of-type(4),
.Subtotal td:nth-of-type(3),
.Subtotal td:nth-of-type(4) {
  background-color: #cedde1;
}

.Edit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Edit div {
  margin: 30px 0 10px;
}

.Edit a {
  padding: 7px 45px;
  border: 2px solid #004e63;
  border: 2px solid var(--main-colour);
  border-radius: 3px;
  color: #fff;
  background-color: #004e63;
  background-color: var(--main-colour);
  text-decoration: none;
  transition: 0.3s ease-in-out;
}

.Edit a:hover {
  border: 2px solid #debc54;
  background-color: #debc54;
  border: 2px solid var(--hover-colour);
  background-color: var(--hover-colour);
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .OrderSummary {
    font-size: 0.9rem;
  }
}
