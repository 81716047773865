.CreateAccountForm {
  margin: 40px auto 0;
}

.FormRow {
  max-width: 55%;
  width: 100%;
  margin: 15px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.FormRow input,
.FormRow select {
  width: 100%;
  padding: 10px;
}

.InputContainer {
  width: 100%;
}

.Label {
  width: 100%;
  margin-bottom: 5px;
}

.SignIn {
  text-align: right;
  margin-top: 30px;
}

.SignIn span {
  color: #004e63;
  color: var(--main-colour);
  text-decoration: underline;
  cursor: pointer;
}

.Separator {
  margin: 40px 0;
  width: 100%;
  height: 1px;
  background: #4e5758;
  color: #4e5758;
  background: var(--grey-colour);
  color: var(--grey-colour);
}

.Error {
  margin-bottom: 5px;
  font-size: 0.95rem;
  text-align: right;
  margin-top: -24px;
}

.Error:before {
  content: '!';
  background-color: red;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  padding: 1px 9px;
  border-radius: 50%;
  margin-right: 10px;
}

.Disclaimer {
  background-color: #e6eef0;
  padding: 5px 25px;
  text-align: center;
  font-size: 15px;
}

.Button {
  text-align: right;
  position: relative;
}

.Button:hover .Tooltip {
  opacity: 1;
}

.Tooltip {
  font-size: 13px;
  max-width: 223px;
  position: absolute;
  right: 0;
  top: 85px;
  text-align: center;
  padding: 5px;
  background: #e6eef0;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  opacity: 0;
}

.CheckboxContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

input[type='checkbox' i] {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {
  input[type='checkbox' i] {
    width: 60px;
  }

  .CheckboxContainer {
    font-size: 0.9rem;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 575px) {
  .FormRow {
    max-width: 100%;
  }

  .SignIn span {
    display: block;
  }

  .Error:before {
    font-size: 0.8rem;
    padding: 1px 7px;
  }
}
