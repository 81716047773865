.PageTitle {
  text-align: center;
  font-size: 55px;
  color: #004e63;
  color: var(--main-colour);
  font-weight: normal;
  margin-bottom: 40px;
}

@media only screen and (max-width: 575px) {
  .PageTitle {
    font-size: 40px;
  }
}
