.Navbar {
  margin: 30px 0px 60px;
  background-color: #fff;
  padding: 15px 80px;
}

.NavbarContainer {
  display: flex;
  justify-content: space-between;
}

.NavbarContainer a {
  text-decoration: none;
  color: #004e63;
  color: var(--main-colour);
  text-align: center;
  position: relative;
}

.NavbarContainer a:not(:first-of-type) {
  margin-left: 5px;
}

.NavbarContainer a:not(:last-of-type) {
  margin-right: 5px;
}

.NavbarContainer div {
  font-size: 15px;
}

.NavbarNumber {
  font-size: 16px;
  border: 2px solid #004e63;
  border: 2px solid var(--main-colour);
  border-radius: 50%;
  padding: 9px 0px;
  width: 40px;
  margin: 0 auto 10px;
}

.Complete {
  background: #004e63;
  background: var(--main-colour);
  color: #fff;
}

.Disabled,
.Disabled a,
.Disabled div {
  color: #80a9b3;
}

.Disabled .NavbarNumber {
  border: 2px solid #80a9b3;
}

@media only screen and (max-width: 729px) {
  .Separator {
    background-color: #80a9b3;
    width: 100%;
    height: 2px;
    max-width: 80px;
    margin: 19px auto 0;
  }
}

@media only screen and (max-width: 575px) {
  .Navbar {
    padding: 10px 20px 0 20px;
  }

  .NavbarContainer a div:last-of-type {
    display: none;
  }
}
