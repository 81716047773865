.Footer {
    background-color: #000;
    margin-top: 25px;
    color: #fff;
    font-size: 12px;
}

.FooterContainer {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 25px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Footer a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

.Column:nth-of-type(2) {
    text-align: center;
}

.Column i {
    font-size: 22px;
    padding-left: 15px;
}

@media only screen and (max-width: 768px) {
    .FooterContainer {
        justify-content: center;
        text-align: center;
        flex-direction: column;
    }

    .Column:last-of-type {
        margin-top: 1em;
    }

    .Column a:first-of-type i {
        padding-left: 0;
    }
}

@media only screen and (max-width: 575px) {
    .Column i {
        padding: 10px;
        font-size: 27px;
    }
}
