.SearchBarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SearchBarContainer div:first-of-type {
  width: 100%;
  font-size: 25px;
  color: #004e63;
  color: var(--main-colour);
  text-align: center;
}

.SearchBar {
  float: right;
}

.SearchBar input {
  min-width: 200px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.SearchBar label {
  display: flex;
  align-items: center;
}

.SearchBar i {
  padding: 12px;
  border-radius: 5px;
  background: #004e63;
  background: var(--main-colour);
  color: #fff;
  margin-left: -5px;
}

@media only screen and (max-width: 768px) {
  .SearchBarContainer div:first-of-type {
    margin-bottom: 5px;
  }

  .SearchBar,
  .SearchBar input {
    float: none;
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .SearchBarContainer div:first-of-type {
    font-size: 18px;
  }

  .SearchBarContainer {
    display: block;
  }
}
