.Prefooter {
  margin-top: 100px;
  color: #004e63;
  color: var(--main-colour);
  font-size: 16px;
}

.PrefooterContainer {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
}

.Column {
  width: 33.33%;
}

.Column a {
  color: #004e63;
  color: var(--main-colour);
  text-decoration: none;
}

.Column i {
  color: #fff;
  font-size: 26px;
  background-color: #004e63;
  background-color: var(--main-colour);
  border-radius: 50%;
  transition: 0.3s ease-in;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.Column p {
  font-size: 25px;
  margin: 15px 0;
}

.Column a:hover i {
  background-color: #debc54;
  background-color: var(--hover-colour);
}

@media only screen and (max-width: 575px) {
  .Prefooter {
    font-size: 14px;
  }

  .Column p,
  .Column i {
    font-size: 20px;
  }
}
