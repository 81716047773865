.App {
  background-color: #fff;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  border-top: 50px solid #004e63;
  border-top: 50px solid var(--main-colour);
  box-sizing: border-box;
}

.AppContainer {
  max-width: 1200px;
  min-height: 91vh;
  margin: 0px auto;
  padding: 40px 15px 0px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.65);
}

.Content {
  max-width: 1050px;
  width: 100%;
  margin: 0 auto;
}
