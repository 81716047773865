.LicenceItem {
  width: 20%;
  padding: 13px;
  /* margin: 5px; */
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.LicenceItem:first-of-type,
.LicenceItem:nth-of-type(5n + 1) {
  margin-left: 0;
  /* margin-right: 5px; */
}

.LicenceItem:nth-of-type(5n) {
  margin-right: 0;
  /* margin-left: 5px; */
}

.LicenceItem p {
  font-size: 0.9rem;
  margin: 5px 0;
  text-align: center;
}

.LicenceItem button {
  min-width: auto;
  margin-top: 5px;
  width: 100%;
  background-color: #004e63;
  background-color: var(--main-colour);
  box-shadow: none;
  border: 2px solid #004e63;
  border: 2px solid var(--main-colour);
  padding: 7px 5px;
  font-size: 1.1rem;
  color: #fff;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.LicenceItem button:hover {
  background-color: #debc54;
  border: 2px solid #debc54;
  background-color: var(--hover-colour);
  border: 2px solid var(--hover-colour);
}

.AddLicence {
  width: 100%;
  display: flex;
}

.AddLicence div {
  width: 100%;
  background-color: #fff;
  padding: 0 10px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #004e63;
  border-bottom: 1px solid #004e63;
  border-top: 1px solid var(--main-colour);
  border-bottom: 1px solid var(--main-colour);
}

@media only screen and (max-width: 780px) {
  .LicenceItem:first-of-type,
  .LicenceItem:nth-of-type(5n + 1) {
    margin-left: unset;
    margin-right: unset;
  }

  .LicenceItem:nth-of-type(5n) {
    margin-right: unset;
    margin-left: unset;
  }

  .LicenceItem {
    margin: 5px 0;
  }
}

@media only screen and (max-width: 575px) {
  .LicenceItem {
    width: 48%;
  }
}
