:root {
  --main-colour: #004e63;
  --hover-colour: #debc54;
  --grey-colour: #4e5758;
}

* {
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4e5758;
  color: var(--grey-colour);
  line-height: 1.2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #fff;
  text-decoration: none;
}

button {
  margin-top: 30px;
  min-width: 140px;
  background-color: #004e63;
  border: 2px solid #004e63;
  background-color: var(--main-colour);
  border: 2px solid var(--main-colour);
  box-shadow: none;
  padding: 7px 30px;
  font-size: 1.1rem;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

button:hover {
  background-color: #debc54;
  border: 2px solid #debc54;
  background-color: var(--hover-colour);
  border: 2px solid var(--hover-colour);
}

button:disabled {
  background-color: rgba(0, 78, 99, 0.5);
  border: 2px solid rgba(0, 78, 99, 0);
  color: rgba(255, 255, 255, 0.7);
  cursor: default;
}

*[disabled] {
  pointer-events: none !important;
}

input,
select {
  font-family: 'Roboto';
  font-size: 0.9rem;
  color: #4e5758;
  color: var(--grey-colour);
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.subtitle {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  line-height: 1.3;
  text-align: center;
}

.StripeElement {
  background-color: #fff;
  padding: 10px;
  border: 1px solid rgb(169, 169, 169);
}

@media only screen and (max-width: 575px) {
  button {
    font-size: 1em;
  }
}
