.LicenceLength {
  float: left;
}

.LicenceLength label {
  justify-content: center;
}

.LicenceLength label > span {
  margin-right: 20px;
  color: #004e63;
  color: var(--main-colour);
  font-size: 1.2em;
  margin-bottom: 10px;
}

.LicenceLength select {
  padding: 8px 30px 8px 10px;
  font-family: Roboto;
  font-size: 0.9em;
  color: #4e5758;
  color: var(--grey-colour);
  border-radius: 5px;
  width: 150px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.ContinueButton {
  margin-top: 30px;
}

.Disclaimer {
  font-size: 14px;
  text-align: right;
  margin-top: 10px;
}

.Disclaimer a {
  color: #004e63;
  color: var(--main-colour);
  font-weight: bold;
  text-decoration: none;
}

.ButtonContainer {
  text-align: right;
  width: 100%;
}

.Plans {
  margin: 40px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.SectionTitle p {
  font-size: 30px;
  color: #004e63;
  color: var(--main-colour);
  text-align: center;
  margin-bottom: 0;
  margin-top: 40px;
}

.PriceBar {
  margin-top: 7px;
  float: left;
  margin-left: 15px;
  color: #004e63;
  color: var(--main-colour);
  font-size: 1.2em;
}

@media only screen and (max-width: 930px) {
  .PriceBar {
    margin-left: 0;
    float: none;
    clear: both;
  }
}

@media only screen and (max-width: 768px) {
  .LicenceLength {
    margin-top: 20px;
    float: none;
    text-align: center;
  }

  .PriceBar {
    text-align: center;
    font-size: 17px;
    margin-top: 15px;
  }
}
