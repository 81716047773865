.EmailValidation {
  max-width: 600px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  line-height: 1.3;
}

.ResendEmail {
  background-color: #e6eef0;
  max-width: 400px;
  width: 100%;
  padding: 25px 15px;
  text-align: center;
  border-radius: 5px;
  margin: 40px auto 0;
}
