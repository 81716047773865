.LicenceList {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin: 20px 0;
    clear: both;
}

.Error {
    text-align: center;
    width: 100%;
    background-color: #e6eef0;
    border-radius: 5px;
    padding: 20px 0;
}

@media only screen and (max-width: 780px) {
    .LicenceList {
        justify-content: space-between;
    }
}
