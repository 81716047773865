.FormRow {
  max-width: 50%;
  width: 100%;
  margin: 15px auto;
  display: flex;
  align-items: center;
  position: relative;
}

.FormRow input {
  padding: 10px;
}

.FormRow label {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.FormRow span {
  width: 100%;
  margin-bottom: 5px;
}

.FormRow .InputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.InfoRow label {
  width: 40%;
}

.InfoRow .InputContainer {
  width: 100%;
}

.InfoRow label:first-of-type {
  width: 60%;
  margin-right: 10px;
}

.InfoRow label:first-child span {
  width: 100%;
}

.InfoRow label:last-of-type .InputContainer {
  width: 100%;
}

.InfoRow label:last-of-type span {
  justify-content: center;
  width: auto;
  padding: 0 10px 0 0;
}

.PayButton {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.CVCQuestion {
  width: 20px;
  height: 18px;
  font-size: 0.9rem;
  text-align: center;
  background-color: #004e63;
  background-color: var(--main-colour);
  border-radius: 50%;
  color: #fff;
  margin-right: 15px;
}

.CVCResponse {
  font-size: 0.8rem;
  opacity: 0;
  position: absolute;
  right: -215px;
  top: 24px;
  max-width: 200px;
  background-color: #004e6336;
  padding: 5px;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
}

.InfoRow label:last-of-type:hover .CVCResponse {
  opacity: 1;
}

.Error {
  margin: 40px 0 0;
  text-align: center;
  color: red;
}

.GoBack {
  margin-top: 30px;
  color: #004e63;
  color: var(--main-colour);
}

@media only screen and (max-width: 575px) {
  .FormRow label {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .FormRow span {
    margin-bottom: 5px;
    width: 100%;
  }

  .FormRow,
  .FormRow .InputContainer,
  .InfoRow label:first-child span,
  .InfoRow label:last-of-type .InputContainer {
    max-width: 100%;
    width: 100%;
  }

  .InfoRow label:first-of-type .InputContainer {
    width: 90%;
  }

  .InfoRow label:last-of-type span {
    padding-left: 0;
  }

  .CVCQuestion,
  .CVCResponse {
    display: none;
  }
}
